import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/SEO";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import * as pageStyles from "./page.module.less";

const ThankYou = (props) => {
  return (
    <Layout hideNewsletterFooter>
      <Seo title="Thank you for signing up for our newsletter." />
      <PageHeader header="Thank you for signing up for our newsletter." />
      <Container>
        <Row
          className={`justify-content-md-center ${pageStyles.pageParentRow} ${pageStyles.pageRow}`}
        >
          <Col lg={10} md={12}>
            We will be taking off shortly and send you regular industry
            insights, case studies, and updates about our services and products.
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
export default ThankYou;
